import React from 'react'

import { v4 as uuid } from 'uuid'

import { showToast } from 'components/Toast'
import * as s from '../styles'

import tbanksPrivacy from 'assets/files/tbanks_privacy.pdf'
import CompanyForm from './CompanyForm'
import RepresentantForm from './RepresentantForm'
import TokenForm from './TokenForm'
import PixSimplesModalContainer from './PixSimplesModalContainer'
import WelcomeStep from './WelcomeStep'

import AnyBankTbanksRepresentantForm from './AnyBankTbanksRepresentantForm'
import AnyBankItauCompanyForm from './AnyBankItauCompanyForm'
import AnyBankItauDestinationAccountForm from './AnyBankItauDestinationAccountForm'
import AnybankItauWelcomeStep from './AnyBankItauWelcomeStep'
import AnyBankItauSuccessModal from './AnyBankItauSuccessModal'
import TbanksCompanyForm from './AnyBankTbanksCompanyForm'
import AnyBankItauLegalRepresentantativeForm from './AnyBankItauLegalRepresentantiveForm'
import Loading from './Loading'
import AnyBankItauDocumentsVerificationModal from './AnyBankItauDocumentsVerificationModal'
import AnyBankItauEndOnboardModal from './AnyBankItauEndOnboardModal'

export type ItauAnyBankOnboardStatusType =
  | 'finished'
  | 'rejected'
  | 'in-progress'
  | ''

interface IProps {
  isOpen: string
  handleClose: () => any
  isFrom: string
  fromStep?: number
  fromStatus?: ItauAnyBankOnboardStatusType
  companyDocument?: string
  pixUuid?: string
}

export interface FormProps {
  destinationAccount: {
    account: string
    bank: string
    branch: string
  }
  company: {
    name: string
    phone_number: string
    created_at: string
    email: string
    document_number: string
  }
  representative: {
    document_number: string
    name: string
    email: string
    phone_number: string
  }
  store_cnpj_cpf: string
}

export interface FormPropsItau {
  destination_account: {
    document_number: string
    pix_dict_key: string
    pix_dict_key_type: string
  }
  company: {
    document_number: string
    company_name: string
    fantasy_name: string
    income_invoicing: string
    phone_ddd: string
    phone_number: string
    email: string
    address: {
      street: string
      number: string
      complementary: string
      neighborhood: string
      city: string
      state: string
      zip_code: string
    }
  }
  legal_representative: {
    document_number: string
    name: string
    mother_name: string
    birth_date: string
    phone_ddd: string
    phone_number: string
    email: string
    address: {
      street: string
      number: string
      complementary: string
      neighborhood: string
      city: string
      state: string
      zip_code: string
    }
  }
}

const WalletSettingsFormPixSimples: React.FC<IProps> = ({
  isOpen,
  handleClose,
  isFrom,
  fromStep = 0,
  fromStatus = '',
  companyDocument = '',
  pixUuid = '',
}) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(false)
  const [step, setStep] = React.useState<number>(fromStep)
  const [form, setForm] = React.useState<FormProps>({} as FormProps)
  const [formItau, setFormItau] = React.useState<FormPropsItau>(
    {} as FormPropsItau
  )
  const [isLoadingAnyBank, setIsLoadingAnyBank] = React.useState(false)
  const [registrationId, setRegistrationId] = React.useState<string>(pixUuid)
  const [callCompanyValidation, setCallCompanyValidation] =
    React.useState(false)
  const [callDocumentSubmission, setCallDocumentSubmission] =
    React.useState(false)
  const [callRepresentativeValidation, setCallRepresentativeValidation] =
    React.useState(false)
  const [enableGoToDocumentsVerification, setEnableGoToDocumentsVerification] =
    React.useState(false)
  const itauAnyBankOnboardStatus: ItauAnyBankOnboardStatusType = fromStatus

  const nextStep = () => {
    if (
      (isFrom === 'tbanks' || isFrom === 'conexaoItau') &&
      !isChecked &&
      step === 0
    ) {
      showToast({
        type: 'error',
        message:
          'Para continuar é necessário concordar com a a política de privacidade',
      })
    } else if (
      step === 0 ||
      step === 1 ||
      step === 2 ||
      step === 3 ||
      step === 4
    ) {
      setStep(step + 1)
    }
  }

  const previousStep = () => {
    if (step === 0) {
      handleClose()
    } else if (step === 1 || step === 2 || step === 3 || step === 4) {
      setStep(step - 1)
    }
  }

  const WelcomeStepItau = () => {
    return (
      <>
        <AnybankItauWelcomeStep isFrom={isFrom} />
        <s.Footer>
          <s.CheckboxWrapper>
            {
              <>
                <s.Checkbox
                  id={uuid()}
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked)
                  }}
                />
                <s.Text>
                  Declaro estar ciente das
                  <s.ButtonLink href={null} target="_blank">
                    Políticas de privacidade
                  </s.ButtonLink>{' '}
                  do Banco itaú
                </s.Text>
              </>
            }
          </s.CheckboxWrapper>
          <s.RightButtonAnybank
            disabled={!isChecked}
            onClick={() => {
              nextStep()
            }}
            width="200px"
            height="40px"
          >
            Começar Cadastro
            <s.Icon name="arrowright" fill="whiteshipay" />
          </s.RightButtonAnybank>
        </s.Footer>
      </>
    )
  }

  const welcomeStep = () => {
    return (
      <>
        <WelcomeStep isFrom={isFrom} />
        <s.Footer>
          <s.CheckboxWrapper>
            {isFrom === 'tbanks' ? (
              <>
                <s.Checkbox
                  id={uuid()}
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked)
                  }}
                />
                <s.Text>
                  Declaro estar ciente das
                  <s.ButtonLink href={tbanksPrivacy} target="_blank">
                    Políticas de privacidade
                  </s.ButtonLink>{' '}
                  do TBankS
                </s.Text>
              </>
            ) : null}
          </s.CheckboxWrapper>
          <s.RightButtonAnybank
            onClick={() => {
              nextStep()
            }}
            width="200px"
            height="40px"
          >
            Começar Cadastro
            <s.Icon name="arrowright" fill="whiteshipay" />
          </s.RightButtonAnybank>
        </s.Footer>
      </>
    )
  }

  const renderModal = () => {
    if (isFrom === 'conexaoItau' && isLoadingAnyBank) {
      return <Loading />
    }
    if (step === 0) {
      if (isFrom === 'conexaoItau') {
        return WelcomeStepItau()
      }
      return welcomeStep()
    }

    if (step === 1) {
      if (isFrom === 'conexaoItau' && !isLoadingAnyBank) {
        return (
          <AnyBankItauDestinationAccountForm
            pixFormState={[formItau, setFormItau]}
            nextStep={nextStep}
            previousStep={previousStep}
            isLoadingState={[isLoadingAnyBank, setIsLoadingAnyBank]}
            callRepresentantiveValidationState={[
              callRepresentativeValidation,
              setCallRepresentativeValidation,
            ]}
            setAllowedToGoToNextStep={setEnableGoToDocumentsVerification}
          />
        )
      }

      if (isFrom === 'tbanks') {
        return (
          <TbanksCompanyForm
            nextStep={nextStep}
            isFrom={isFrom}
            previousStep={previousStep}
            pixFormState={[form, setForm]}
          />
        )
      }
      return (
        <CompanyForm
          nextStep={nextStep}
          isFrom={isFrom}
          previousStep={previousStep}
          pixFormState={[form, setForm]}
        />
      )
    }

    if (step === 2) {
      if (isFrom === 'conexaoItau' && !isLoadingAnyBank) {
        return (
          <AnyBankItauDocumentsVerificationModal
            companyDocumentNumber={formItau.company?.document_number}
            legalRepresentativeDocumentNumber={
              formItau.legal_representative?.document_number
            }
            nextStep={nextStep}
            previousStep={previousStep}
            callDocumentSubmissionState={[
              callDocumentSubmission,
              setCallDocumentSubmission,
            ]}
            isLoadingState={[isLoadingAnyBank, setIsLoadingAnyBank]}
            registrationIdState={[registrationId, setRegistrationId]}
          />
        )
      }
      if (isFrom === 'conexaoItau' && isLoadingAnyBank) {
        return <Loading />
      }

      if (isFrom === 'tbanks') {
        return (
          <AnyBankTbanksRepresentantForm
            nextStep={nextStep}
            isFrom={isFrom}
            previousStep={previousStep}
            pixFormState={[form, setForm]}
            closeModal={handleClose}
          />
        )
      }

      return (
        <RepresentantForm
          nextStep={nextStep}
          isFrom={isFrom}
          previousStep={previousStep}
          pixFormState={[form, setForm]}
        />
      )
    }

    if (step === 3) {
      if (isFrom === 'conexaoItau') {
        return (
          <AnyBankItauCompanyForm
            companyDocumentNumber={
              formItau.company?.document_number || companyDocument
            }
            nextStep={nextStep}
            isFrom={isFrom}
            callCompanyValidationState={[
              callCompanyValidation,
              setCallCompanyValidation,
            ]}
            previousStep={previousStep}
            pixFormState={[formItau, setFormItau]}
          />
        )
      }

      return (
        <TokenForm
          isFrom={isFrom}
          previousStep={previousStep}
          handleClose={handleClose}
          pixFormState={form}
        />
      )
    }

    if (step === 4) {
      if (isFrom === 'conexaoItau' && !isLoadingAnyBank) {
        return (
          <AnyBankItauLegalRepresentantativeForm
            legalRepresentativeDocumentNumber={
              formItau.legal_representative?.document_number || ''
            }
            isFrom={isFrom}
            callRepresentantiveValidationState={[
              callRepresentativeValidation,
              setCallRepresentativeValidation,
            ]}
            nextStep={nextStep}
            previousStep={previousStep}
            registrationId={registrationId}
            pixFormState={[formItau, setFormItau]}
            isLoadingState={[isLoadingAnyBank, setIsLoadingAnyBank]}
          />
        )
      }
    }

    if (step === 5) {
      if (isFrom === 'conexaoItau' && itauAnyBankOnboardStatus) {
        return (
          <AnyBankItauEndOnboardModal
            status={itauAnyBankOnboardStatus}
            handleClose={handleClose}
          />
        )
      }

      if (isFrom === 'conexaoItau' && !itauAnyBankOnboardStatus) {
        return <AnyBankItauSuccessModal handleClose={handleClose} />
      }

      return <></>
    }
  }

  const renderButtons = () => {
    if (step === 0) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }

    if (step === 1 && !isLoadingAnyBank) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                previousStep()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="verify-documents-button"
              width="304px"
              height="42px"
              disabled={!enableGoToDocumentsVerification || isLoadingAnyBank}
              onClick={() => {
                setCallRepresentativeValidation(true)
              }}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              {isLoadingAnyBank ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : (
                <s.Icon name="arrowright" fill="white" />
              )}
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step === 2) {
      if (isLoadingAnyBank) {
        return (
          <s.PreviousNextButtonContainer>
            <div style={{ height: '42px' }} />
            <div style={{ height: '42px' }} />
          </s.PreviousNextButtonContainer>
        )
      }

      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                previousStep()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="verify-documents-button"
              width="304px"
              height="42px"
              disabled={isLoadingAnyBank}
              onClick={() => setCallDocumentSubmission(true)}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              {isLoadingAnyBank ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : (
                <s.Icon name="arrowright" fill="white" />
              )}
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }
    if (step === 3) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                previousStep()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
              disabled={!!companyDocument}
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="company-validation-button"
              width="304px"
              height="42px"
              onClick={() => setCallCompanyValidation(true)}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              <s.Icon name="arrowright" fill="white" />
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step === 4 && isLoadingAnyBank) {
      return (
        <s.PreviousNextButtonContainer>
          <div style={{ height: '42px' }} />
          <div style={{ height: '42px' }} />
        </s.PreviousNextButtonContainer>
      )
    }

    if (step === 4 && !isLoadingAnyBank) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                previousStep()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="legalRepresentative-validation-button"
              width="304px"
              height="42px"
              onClick={() => setCallRepresentativeValidation(true)}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              <s.Icon name="arrowright" fill="white" />
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step === 5 && !itauAnyBankOnboardStatus) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="finish-onboard-button"
              width="100%"
              height="42px"
              onClick={() => handleClose()}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Finalizar
              </s.Text>
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }
    if (step === 5 && itauAnyBankOnboardStatus) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="finish-onboard-button"
              width="100%"
              height="42px"
              color="whitedetailed"
              onClick={() => handleClose()}
            >
              <s.Text margin="0 8px 0 0" color="graytheme6" type="headline">
                Fechar
              </s.Text>
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }
  }

  return (
    <>
      <PixSimplesModalContainer
        step={step}
        isFrom={isFrom}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        {isFrom === 'conexaoItau' && step > 0 ? (
          <>{renderButtons()}</>
        ) : (
          <s.CloseButton
            data-gtm-subname={`modal anybank`}
            data-gtm-name="botao fechar"
            data-gtm-type="click"
            data-gtm-clicktype="button"
            onClick={handleClose}
            name="closesquare"
            fill="white"
            width={28}
            height={28}
          />
        )}
        {renderModal()}
      </PixSimplesModalContainer>
    </>
  )
}

export default WalletSettingsFormPixSimples
