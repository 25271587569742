import {
  isConexaoItau,
  isLocalHost,
  isQuality,
  isSandbox,
  isSpacePay,
  isStaging,
  isWhiteLabel,
} from 'helpers'
import { checkUserClaim } from 'helpers/claims'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import React from 'react'
import { UserSessionInfo } from 'store/modules/auth/types'
import { useTheme } from 'styled-components'
import { themeColors } from 'styles/theme'
import { v4 as uuid } from 'uuid'

import originalIcon from 'assets/pix/original-card-icon.svg'
import picpayIcon from 'assets/pix/picpay-card-icon.svg'
import conexaoIcon from 'assets/pix/itau-icon.svg'
import tbanksIcon from 'assets/pix/tbanks-card-icon.svg'
import redeIcon from 'assets/pix/rede-card-icon.svg'
import redeIconWhite from 'assets/pix/rede-card-icon-white.svg'
import { SystemWalletBff } from 'hooks/useGetSystemWalletsFromBff'
import notfoundpix from 'assets/images/not-found-pix.svg'
import notfoundpix2 from 'assets/images/not-found-pix2.svg'

import * as s from './styles'

interface IProps {
  options?: { value: string; checked: boolean }[]
  hasError: boolean
  isLoading: boolean
  wallets: SystemWalletBff[]
  setWalletSettingsModal: React.Dispatch<React.SetStateAction<any>>
}

const SystemWallets: React.FC<IProps> = ({
  options,
  hasError,
  isLoading,
  wallets,
  setWalletSettingsModal,
}) => {
  const theme = useTheme()

  type OptionsFeature = 'cashin' | 'cashout' | 'bank_slip_settings' | 'anybank'

  const isOptionFeatureChecked = (feature: OptionsFeature): boolean => {
    const cashinOption = options.find((option) => option.value === feature)
    return !!cashinOption && cashinOption.checked
  }

  const renderAllowedWallets = React.useMemo(() => {
    if (isSpacePay()) {
      return wallets
        .filter((wallet) => wallet.wallet_name != 'portobank')
        .map((wallet) => (
          <s.WalletCard
            clickCallBack={() => {
              setWalletSettingsModal(wallet.wallet_name)
            }}
            name={wallet.wallet_name}
            color={wallet.wallet_name as keyof typeof themeColors}
            disabled={!wallet.active}
            type="pix"
            key={uuid()}
            height={128}
            width={320}
          />
        ))
    }

    return wallets.map((wallet) => (
      <s.WalletCard
        clickCallBack={() => {
          setWalletSettingsModal(wallet.wallet_name)
        }}
        name={wallet.wallet_name}
        color={wallet.wallet_name as keyof typeof themeColors}
        disabled={!wallet.active}
        type="pix"
        key={uuid()}
        height={128}
        width={320}
      />
    ))
  }, [wallets])

  const renderAnybankAndCashout = React.useMemo(() => {
    if (wallets.length) {
      const originalCardClaim = 'panel_pages_pix@card_original'
      const tbanksCardClaim = 'panel_pages_pix@card_tbanks'
      const itauAnybankCardClaim = 'panel_pages_pix@card_itau_anybank'
      const picpayAnybankCardClaim = 'panel_pages_pix@card_picpay_anybank'
      const tbanksCashoutCardClaim = 'panel_pages_pix@card_tbanks_cashout'

      let itauAnybankSystemWallet = wallets.find(
        (wallet) => wallet.wallet_name === ('itau_anybank' || 'itau_anybank_v2')
      )
      let picpayAnybankSystemWallet = wallets.find(
        (wallet) => wallet.wallet_name === 'picpay_anybank'
      )

      if (itauAnybankSystemWallet) {
        itauAnybankSystemWallet['active'] = false

        if (
          isQuality() ||
          isSandbox() ||
          isLocalHost() ||
          isStaging() ||
          isConexaoItau()
        ) {
          itauAnybankSystemWallet['active'] = true
        }
      }

      if (picpayAnybankSystemWallet) {
        picpayAnybankSystemWallet['active'] = false

        if (isStaging() || isQuality() || isSandbox() || isLocalHost()) {
          picpayAnybankSystemWallet['active'] = true
        }
      }

      const originalAnybankSystemWallet = wallets.find(
        (wallet) => wallet.wallet_name === 'original'
      )
      const tbanksAnybankSystemWallet = wallets.find(
        (wallet) => wallet.wallet_name === 'tbanks'
      )

      const currentUserSession: UserSessionInfo = JSON.parse(
        getValueFromLocalStorage('currentUserSession')
      )

      const RenderItauRedeCard = () => {
        if (
          isSpacePay() ||
          checkUserClaim(
            currentUserSession?.user_claims,
            itauAnybankCardClaim
          ) ||
          !itauAnybankSystemWallet
        ) {
          return <></>
        }
        if (isConexaoItau()) {
          return (
            <s.RedeCard
              data-testid="walletcard-rede-anybank"
              disabled={!itauAnybankSystemWallet?.active}
              onClick={() => {
                if (itauAnybankSystemWallet?.active) {
                  setWalletSettingsModal('conexaoItau')
                }
              }}
            >
              <img
                src={itauAnybankSystemWallet?.active ? redeIcon : redeIconWhite}
                alt="imagem pix Conexao Rede"
              />
              <s.FooterWrapper>
                <s.TextCardFooter>
                  <s.Icon name="cashinarrowdown" fill="white" />
                  cobrança
                </s.TextCardFooter>
                <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
              </s.FooterWrapper>
            </s.RedeCard>
          )
        } else {
          return (
            <s.ItauCard
              data-testid="walletcard-itau-anybank"
              disabled={!itauAnybankSystemWallet?.active}
              onClick={() => {
                if (itauAnybankSystemWallet?.active) {
                  setWalletSettingsModal('conexaoItau')
                }
              }}
            >
              <img src={conexaoIcon} alt="imagem pix Conexao" />
              <s.FooterWrapper>
                <s.TextCardFooter>
                  <s.Icon name="cashinarrowdown" fill="white" />
                  cobrança
                </s.TextCardFooter>
                <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
              </s.FooterWrapper>
            </s.ItauCard>
          )
        }
      }

      const RenderOriginalCard = () => {
        if (
          checkUserClaim(currentUserSession?.user_claims, originalCardClaim) ||
          isSpacePay() ||
          !originalAnybankSystemWallet
        ) {
          return <></>
        }
        if (theme.domain === 'Shipay' || isWhiteLabel()) {
          return (
            <s.OriginalCard
              data-testid="walletcard-original-anybank"
              disabled={!originalAnybankSystemWallet?.active}
              onClick={() => {
                if (originalAnybankSystemWallet?.active) {
                  setWalletSettingsModal('original')
                }
              }}
            >
              <img src={originalIcon} alt="imagem pix Original" />
              <s.FooterWrapper>
                <s.TextCardFooter>
                  <s.Icon name="cashinarrowdown" fill="white" />
                  cobrança
                </s.TextCardFooter>
                <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
              </s.FooterWrapper>
            </s.OriginalCard>
          )
        } else {
          return (
            <s.RedeCard
              data-testid="walletcard-rede-anybank"
              disabled={!originalAnybankSystemWallet?.active}
              onClick={() => {
                if (originalAnybankSystemWallet?.active) {
                  setWalletSettingsModal('original')
                }
              }}
            >
              <img
                src={
                  originalAnybankSystemWallet?.active ? redeIcon : redeIconWhite
                }
                alt="imagem pix Rede"
              />
              <s.FooterWrapper>
                <s.TextCardFooter>
                  <s.Icon name="cashinarrowdown" fill="white" />
                  cobrança
                </s.TextCardFooter>
                <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
              </s.FooterWrapper>
            </s.RedeCard>
          )
        }
      }

      const RenderPicPayAnybankCard = () => {
        if (
          checkUserClaim(
            currentUserSession?.user_claims,
            picpayAnybankCardClaim
          ) ||
          !picpayAnybankSystemWallet
        ) {
          return <></>
        }
        return (
          <s.PicPayCard
            data-testid="walletcard-picpay-anybank"
            disabled={!picpayAnybankSystemWallet?.active}
            onClick={() => {
              if (picpayAnybankSystemWallet?.active) {
                setWalletSettingsModal('picpay_anybank')
              }
            }}
          >
            <img src={picpayIcon} alt="imagem pix Original" />
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
              <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
            </s.FooterWrapper>
          </s.PicPayCard>
        )
      }

      const RenderTbanksCashinCard = () => {
        if (
          checkUserClaim(currentUserSession?.user_claims, tbanksCardClaim) ||
          !tbanksAnybankSystemWallet
        ) {
          return <></>
        }

        if (
          isOptionFeatureChecked('cashout') ||
          isOptionFeatureChecked('bank_slip_settings')
        ) {
          return <></>
        }

        if (isConexaoItau()) {
          return (
            <s.RedeCard
              data-testid="walletcard-rede-anybank"
              disabled={!tbanksAnybankSystemWallet?.active}
              onClick={() => {
                if (tbanksAnybankSystemWallet?.active) {
                  setWalletSettingsModal('tbanks')
                }
              }}
            >
              <img
                src={
                  tbanksAnybankSystemWallet?.active ? redeIcon : redeIconWhite
                }
                alt="imagem pix TBankS"
              />
              <s.FooterWrapper>
                <s.TextCardFooter>
                  <s.Icon name="cashinarrowdown" fill="white" />
                  cobrança
                </s.TextCardFooter>
                <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
              </s.FooterWrapper>
            </s.RedeCard>
          )
        } else {
          return (
            <s.TBanksCard
              disabled={!tbanksAnybankSystemWallet?.active}
              data-testid="walletcard-tbanks-anybank"
              onClick={() => {
                if (tbanksAnybankSystemWallet?.active) {
                  setWalletSettingsModal('tbanks')
                }
              }}
            >
              <img src={tbanksIcon} alt="imagem pix TBankS" />
              <s.FooterWrapper>
                <s.TextCardFooter>
                  <s.Icon name="cashinarrowdown" fill="white" />
                  cobrança
                </s.TextCardFooter>
                <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
              </s.FooterWrapper>
            </s.TBanksCard>
          )
        }
      }

      const RenderTbanksCashoutCard = () => {
        if (
          checkUserClaim(
            currentUserSession?.user_claims,
            tbanksCashoutCardClaim
          )
        ) {
          return <></>
        }

        if (
          isOptionFeatureChecked('cashin') ||
          isOptionFeatureChecked('anybank') ||
          isOptionFeatureChecked('bank_slip_settings')
        ) {
          return <></>
        }

        if (tbanksAnybankSystemWallet?.cashout_active) {
          return (
            <s.TBanksCardCashout
              disabled={!tbanksAnybankSystemWallet?.cashout_active}
              data-testid="walletcard-tbanks-cashout"
              onClick={() => {
                if (tbanksAnybankSystemWallet?.cashout_active) {
                  setWalletSettingsModal('wallet-tbanks-cashout')
                }
              }}
            >
              <img src={tbanksIcon} alt="imagem pix TBankS Envios" />
              <s.FooterWrapper>
                <s.TextCardFooter>
                  <s.Icon name="cashoutarrowup" fill="white" />
                  envio
                </s.TextCardFooter>
              </s.FooterWrapper>
            </s.TBanksCardCashout>
          )
        } else {
          return <></>
        }
      }

      return (
        <>
          <>
            <RenderItauRedeCard />
            <RenderOriginalCard />
            <RenderTbanksCashinCard />
            <RenderTbanksCashoutCard />
            <RenderPicPayAnybankCard />
          </>
        </>
      )
    }
  }, [wallets])

  const renderCards = React.useMemo(() => {
    if (hasError) {
      return (
        <s.Text color="maincolor" data-testid="error-text" type="heading3">
          Erro na API, tente novamente mais tarde
        </s.Text>
      )
    }
    if (isLoading) {
      return (
        <s.LoadingWrapper>
          <s.Text
            data-testid="loading-text-wallets"
            color="maincolor"
            type="heading3"
          >
            Carregando...
          </s.Text>
          <s.Loading
            type="spinner"
            data-testid="loading-spinner1"
            color="maincolor"
          />
        </s.LoadingWrapper>
      )
    }
    if (
      wallets.length > 0 &&
      !(isOptionFeatureChecked('cashout') && isOptionFeatureChecked('cashin'))
    ) {
      return (
        <>
          {renderAnybankAndCashout}
          {renderAllowedWallets}
        </>
      )
    } else {
      return (
        <>
          <s.Text type="heading3" color="graytheme6">
            Desculpe, não há nenhuma instituição financeira disponível com essas
            funcionalidades simultaneamente. Tente outra combinação de filtros.
          </s.Text>
          <img src={notfoundpix} />
          <img src={notfoundpix2} />
        </>
      )
    }
  }, [hasError, isLoading, wallets, setWalletSettingsModal])

  return <>{renderCards}</>
}

export default SystemWallets
